import {useQueries, useQuery} from "@tanstack/react-query";

export interface ErrorResponse {
    readonly errors?: Error[]
}

export interface Error {
    readonly code: string
    readonly message?: string
    readonly parameters?: ErrorParameters
}

export interface ErrorParameters {
    readonly [key: string]: string
}

export interface User {
    readonly id: string
    readonly name?: string
    readonly avatarUrl?: string
}

export interface Credentials {
    readonly sessionId: string;
    readonly user: User;
}

export interface Invitation {
    readonly id: string;
    readonly createdAt: Date;
    readonly inviterId: string;
}

export interface Connections {
    readonly connections: string[];
}

export interface Idea {
    readonly id: string;
    readonly authorId: string;
    readonly title: string;
    readonly startsAt?: string;
    readonly endsAt?: string;
    readonly accepted?: string[];
    readonly interested?: string[];
    readonly rejected?: string[];
}

export interface IdeaAccessors {
    readonly network: boolean;
    readonly users?: string[];
}

export interface IdeaShareResponse {
    readonly token: string;
}

export interface IdeaList {
    readonly ideas: Idea[];
    readonly cursor?: string;
}

enum HttpMethod {
    DELETE = "DELETE",
    GET = "GET",
    POST = "POST",
}

export interface ApiFetchOptions {
    params?: any;
    sessionId?: null | undefined | string;
    method?: HttpMethod;
}

class W2d2dApiClient {

    apiBaseUrl: string =
        process.env.NODE_ENV === 'production' ? 'https://api.eventer.app/v1/w2d2d/' : 'http://localhost:8888/v1/w2d2d/'

    appBaseUrl: string =
        process.env.NODE_ENV === 'production' ? 'https://w2d2d.eventer.app/' : 'http://localhost:5173/'

    // apiBaseUrl: string = 'https://api.eventer.app/v1/w2d2d/'


    private async fetch<T>(path: string, options?: ApiFetchOptions): Promise<T> {
        const method = options ? (options!!.method || HttpMethod.POST) : HttpMethod.POST
        let headers = new Headers({
            'Accept': 'application/json',
        })
        if (options && options.sessionId) {
            headers.set('Authorization', `Session ${options.sessionId}`)
        }
        if (method === HttpMethod.POST) {
            headers.set('Content-Type', 'application/json')
        }
        let response = await fetch(`${this.apiBaseUrl}${path}`, {
            headers: headers,
            method,
            body: options ? JSON.stringify(options.params) : '',
        });
        if (!response.ok) {
            // special check for 'unauthorized' with sessionId
            if (response.status === 401 && options && options.sessionId) {
                window.location.href = window.location.origin
            }
            return Promise.reject(response)
        }
        const body = await response.text();
        if (body && body.length > 0) {
            return JSON.parse(body);
        } else {
            return {} as T;
        }
    }

    async parseErrorResponse(response: Response) {

        const json = await response.json()

        let errors: Error[] = Array<Error>()
        Object.keys(json).forEach((property) => {
            if (property === 'errors') {
                let value = json[property]
                if (Array.isArray(value)) {

                    value.forEach((errorJson) => {
                        let code = errorJson["code"]
                        if (code.length === 0) {
                            // Seems to be error
                            return
                        }

                        // TODO: parse parameters

                        errors.push({
                            code: code,
                            message: errorJson["message"],
                        })
                    })
                }
            }
        })

        if (errors.length === 0) {
            errors.push(
                {
                    code: "unknown",
                }
            )
        }

        return {
            errors: errors,
        }
    }

    async sessionAuth(
        params: { sessionId: string; },
    ): Promise<Credentials> {
        return this.fetch<Credentials>('auth/session', {
            params: {sessionId: params.sessionId}
        })
    }

    async logout(params: { sessionId: string; }) {
        return this.fetch<undefined>('auth/logout', {sessionId: params.sessionId})
    }

    async getUser(params: { id: string; }): Promise<User> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<User>(
            `users/${params.id}`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async createInvitation(): Promise<Invitation> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Invitation>(
            `invitations`,
            {
                sessionId: sessionId,
            },
        )
    }

    async getInvitation(params: { invitationId: string }): Promise<Invitation> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Invitation>(
            `invitations/${params.invitationId}`,
            {
                method: HttpMethod.GET,
                sessionId: sessionId,
            },
        )
    }

    async acceptInvitation(params: { invitationId: string }): Promise<void> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `invitations/${params.invitationId}/accept`,
            {
                sessionId: sessionId,
            },
        )
    }

    async getConnections(): Promise<Connections> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Connections>(
            `users/me/connections`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async deleteConnection(params: { connectionId: string }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `users/me/connections/${params.connectionId}`,
            {
                sessionId: sessionId,
                method: HttpMethod.DELETE,
            },
        )
    }

    async newIdea(
        params: {
            title: string;
            startsAt?: Date;
            endsAt?: Date;
            accessors?: IdeaAccessors;
        }
    ): Promise<Idea> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Idea>(
            `ideas`,
            {
                sessionId: sessionId,
                params: {
                    title: params.title,
                    startsAt: params.startsAt?.toISOString(),
                    endsAt: params.endsAt?.toISOString(),
                    accessors: params.accessors,
                }
            },
        )
    }

    async getIdea(params: { id: string }): Promise<Idea> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Idea>(
            `ideas/${params.id}`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async shareIdea(params: { id: string }): Promise<IdeaShareResponse> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<IdeaShareResponse>(
            `ideas/${params.id}/share`,
            {
                sessionId: sessionId,
            },
        )
    }

    async joinIdea(params: { id: string, shareToken: string }): Promise<undefined> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `ideas/${params.id}/join/${params.shareToken}`,
            {
                sessionId: sessionId,
            },
        )
    }

    async getIdeas(): Promise<IdeaList> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<IdeaList>(
            `ideas`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async acceptIdea(params: { id: string }): Promise<Idea> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Idea>(
            `ideas/${params.id}/participation/accept`,
            {
                sessionId: sessionId,
                method: HttpMethod.POST,
            },
        )
    }

    async interestedInIdea(params: { id: string }): Promise<Idea> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Idea>(
            `ideas/${params.id}/participation/interested`,
            {
                sessionId: sessionId,
                method: HttpMethod.POST,
            },
        )
    }

    async rejectIdea(params: { id: string }): Promise<Idea> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Idea>(
            `ideas/${params.id}/participation/reject`,
            {
                sessionId: sessionId,
                method: HttpMethod.POST,
            },
        )
    }

    async deleteIdeaParticipation(params: { id: string }): Promise<Idea> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Idea>(
            `ideas/${params.id}/participation`,
            {
                sessionId: sessionId,
                method: HttpMethod.DELETE,
            },
        )
    }

    async deleteIdea(params: { id: string }): Promise<void> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `ideas/${params.id}`,
            {
                sessionId: sessionId,
                method: HttpMethod.DELETE,
            },
        )
    }
}

const w2d2dApiClient = new W2d2dApiClient();

export const useUser = (id: string) => {
    return useQuery({
        queryKey: ['users', id],
        queryFn: () => w2d2dApiClient.getUser({id}),
        staleTime: 1000 * 60 * 60, // 1h
    })
}

export const useUsers = (ids: string[]) => {
    return useQueries({
        queries: ids.map((id) => {
            return {
                queryKey: ['users', id],
                queryFn: () => w2d2dApiClient.getUser({id}),
                staleTime: 1000 * 60 * 60, // 1h
            }
        }),
    })
}

export const useIdea = (id: string) => {
    return useQuery({
        queryKey: ['ideas', id],
        queryFn: () => w2d2dApiClient.getIdea({id}),
        // staleTime: 1000 * 60 * 60, // 1h
    })
}

export {w2d2dApiClient};
