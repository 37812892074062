import {IdeaAccessors, w2d2dApiClient} from "../../api-client"
import {ChangeEvent, useCallback, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    MobileStepper,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import W2d2dRoutes from "../../W2d2dRoutes";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import UsersChooser from "../users/UsersChooser";

interface IdeaDraft {
    title: string;
    startsAt: Date;
    duration: number;
    accessors: IdeaAccessors;
}

const buildIdeaDraft = (): IdeaDraft => {
    let startsAt = new Date()
    if (startsAt.getHours() < 19) {
        startsAt.setHours(19, 0, 0, 0)
    } else {
        startsAt.setHours(startsAt.getHours(), 0, 0, 0)
    }
    return {
        title: "Zusammen abhängen",
        startsAt: startsAt,
        duration: 2,
        accessors: {
            network: true,
        }
    }
}

const steps = ["Wann", "Idee", "Wer"]

export default function AddIdeaPage() {

    const navigate = useNavigate()

    const [idea, setIdea] = useState<IdeaDraft>(buildIdeaDraft())

    const [activeStep, setActiveStep] = useState(0)

    const [connections, setConnections] = useState<null | string[]>(null)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleNetworkAccessChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked
        console.log("checked", checked)
        console.log("idea", idea)
        setIdea((idea) => ({...idea, accessors: {...idea.accessors, network: checked}}))
    }, [idea, setIdea])

    const handleIdeaTitleInputValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let newTitle = e.target.value
        setIdea((idea) => ({...idea, title: newTitle}))
    }, [setIdea])

    const handleIdeaStartsAtChange = useCallback((newStartsAt: Date | null) => {
        if (newStartsAt == null) {
            // ignore null values
            return
        }
        setIdea((idea) => ({...idea, startsAt: newStartsAt}))
    }, [setIdea])

    const handleDurationChange = useCallback((event: SelectChangeEvent) => {
        let newDuration: number = +event.target.value
        setIdea((idea) => ({...idea, duration: newDuration}))
    }, [setIdea])

    const onUserSelectionChange = useCallback((selectedUsers: string[]) => {
        setIdea((idea) => ({...idea, accessors: {...idea.accessors, users: selectedUsers}}))
    }, [idea, setIdea])

    const handleClickAdd = useCallback(async () => {
        let users = idea.accessors.users
        if (idea.accessors.network) {
            users = undefined
        }
        let request = {
            ...idea,
            endsAt: new Date(idea.startsAt.getTime() + 1000 * 60 * 60 * idea.duration),
            accessors: {
                ...idea.accessors,
                users: users, // if network is checked, users will be ignored
            }
        }
        await w2d2dApiClient.newIdea(request)
        navigate(W2d2dRoutes.ideas)
    }, [idea, navigate])

    const stepEl = useCallback((step: number) => {

        if (step === 0) {
            return (
                <>
                    <DateTimePicker
                        label="Wann"
                        value={idea.startsAt}
                        minDate={new Date()}

                        onChange={handleIdeaStartsAtChange}
                        views={['month', 'day', 'hours', 'minutes']}
                    />
                    <Stack direction="row" spacing={3} sx={{marginTop: 4}}>

                        <FormControl
                        >
                            <InputLabel id="duration-select-label">Dauer</InputLabel>
                            <Select
                                id="duration-select"
                                value={`${idea.duration}`}
                                onChange={handleDurationChange}
                                label="Dauer"
                                variant={"outlined"}
                            >
                                <MenuItem value={1}>1 Stunde</MenuItem>
                                <MenuItem value={2}>2 Stunden</MenuItem>
                                <MenuItem value={3}>3 Stunden</MenuItem>
                                <MenuItem value={4}>4 Stunden</MenuItem>
                                <MenuItem value={5}>5 Stunden</MenuItem>
                                <MenuItem value={6}>6 Stunden</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </>
            )
        }

        if (step === 1) {
            return (
                <TextField
                    autoFocus
                    margin="normal"
                    id="idea"
                    label="Idee vorschlagen"
                    type="text"
                    fullWidth
                    required
                    defaultValue={idea.title}
                    variant="standard"

                    onChange={handleIdeaTitleInputValueChange}
                />
            )
        }

        if (step === 2) {
            let connectionsChooserEl
            if (connections && !idea.accessors.network) {
                connectionsChooserEl = (
                    <UsersChooser
                        userIds={connections}
                        selectedUserIds={idea.accessors.users || []}
                        onSelectionChange={onUserSelectionChange}
                    />
                )
            }
            return (
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={idea.accessors.network}
                                onChange={handleNetworkAccessChange}
                            />
                        }
                        label="Alle meine Kontakte"
                    />
                    {connectionsChooserEl}
                </FormGroup>
            )
        }

        // ???
        return (<></>)
    }, [idea, activeStep, connections])

    const backButton = useCallback((step: number) => {

        let text = "Zurück"
        if (activeStep > 0) {
            text = steps[activeStep - 1]
        }

        return (
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft/>
                {text}
            </Button>
        )
    }, [activeStep])

    const nextButton = useCallback((step: number) => {

        let text = "Erstellen"
        if (activeStep < (steps.length - 1)) {
            text = steps[activeStep + 1]
        }

        if (step === (steps.length - 1)) {
            return (
                <Button size="small" onClick={handleClickAdd} disabled={idea.title.trim().length == 0}>
                    {text}
                    <KeyboardArrowRight/>
                </Button>
            )
        }

        return (
            <Button size="small" onClick={handleNext} disabled={activeStep >= (steps.length - 1)}>
                {text}
                <KeyboardArrowRight/>
            </Button>
        )
    }, [idea, activeStep])

    // TODO: use hook?
    useEffect(() => {
        let active = true
        w2d2dApiClient.getConnections()
            .then((response) => {
                if (active) {
                    setConnections(response.connections)
                }
            })
        return () => {
            active = false
        }
    }, [setConnections])

    return (
        <Container
            maxWidth="sm"
            sx={{
                marginTop: 2,
                paddingLeft: 5,
                paddingRight: 5,
            }}
        >
            <MobileStepper
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                sx={{
                    // maxWidth: 400,
                    // flexGrow: 1
                    marginBottom: 2,
                }}
                backButton={backButton(activeStep)}
                nextButton={nextButton(activeStep)}
            />

            {/*<Typography*/}
            {/*    variant="h5"*/}
            {/*    sx={{*/}
            {/*        // maxWidth: 400,*/}
            {/*        // flexGrow: 1*/}
            {/*        marginBottom: 2,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {steps[activeStep]}*/}
            {/*</Typography>*/}

            {stepEl(activeStep)}
        </Container>
    )
}
