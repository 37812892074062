import {useNavigate, useParams} from "react-router-dom";
import {useIdea, w2d2dApiClient} from "../../api-client";
import {formatDateTime, formatTime} from "@eventer/api-client/lib/esm/utils/datetime-util";
import AvatarWidget from "../users/AvatarWidget";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import {MouseEvent, useCallback, useState} from "react";
import W2d2dRoutes from "../../W2d2dRoutes";
import {ContentCopy, Delete, MoreVert, Share} from "@mui/icons-material";
import Session from "../../auth/Session";

interface IdeaPageProps {
    readonly session: Session
}

export default function IdeaPage(props: IdeaPageProps) {

    const {id} = useParams()
    const navigate = useNavigate()

    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(null)

    const ideaHook = useIdea(id!!)

    const [openConfirmDeletionDialog, setOpenConfirmDeletionDialog] = useState(false)

    const [openShareDialog, setOpenShareDialog] = useState(false)

    const [shareUrl, setShareUrl] = useState<null | string>(null)
    const [openShareUrlHint, setOpenShareUrlHint] = useState(false)


    const onMoreMenuClick = useCallback((event: MouseEvent<HTMLElement>) => {
        setMoreMenuAnchorEl(event.currentTarget);
    }, [setMoreMenuAnchorEl])

    const handleMoreMenuClose = useCallback(() => {
        setMoreMenuAnchorEl(null)
    }, [setMoreMenuAnchorEl])


    // TODO: idea must be given
    // const authorHook = useUser(idea.authorId)


    const onDeleteIdeaClick = useCallback(async () => {
        setOpenConfirmDeletionDialog(true)
        handleMoreMenuClose()
    }, [setOpenConfirmDeletionDialog])

    const onCancelDeletion = useCallback(async () => {
        setOpenConfirmDeletionDialog(false)
    }, [setOpenConfirmDeletionDialog])

    const onDelete = useCallback(async () => {
        if (!id) {
            return
        }
        await w2d2dApiClient.deleteIdea({id: id})
        navigate(W2d2dRoutes.ideas)
    }, [id, navigate])

    const onShareIdeaClick = useCallback(async () => {
        handleMoreMenuClose()

        let _shareUrl = shareUrl
        if (shareUrl === null) {
            let response = await w2d2dApiClient.shareIdea({id: id!!})
            _shareUrl = w2d2dApiClient.appBaseUrl + 'ideas/' + id + '/join/' + response.token
            setShareUrl(_shareUrl)
        }

        let shareData = {
            title: "Idee teilen",
            text: "Bist du dabei? \n" + ideaHook.data?.title,
            url: _shareUrl!!,
        }

        if (navigator.share && navigator.canShare(shareData)) {
            try {
                await navigator.share(shareData)
                return
            } catch (error) {
                if (`${error}`.includes('AbortError')) {
                    // abort by user
                    return
                }
                console.error('Error sharing', error)
            }
        }

        // no native share -> fallback
        setOpenShareDialog(true)

    }, [ideaHook.data, shareUrl, setShareUrl, setOpenShareDialog])

    const onShareUrlAddToClipboardClick = useCallback(() => {
        if (shareUrl == null) {
            return
        }
        navigator.clipboard.writeText(shareUrl)
        setOpenShareUrlHint(true)
    }, [shareUrl, setOpenShareUrlHint])

    const handleShareUrlHintClose = useCallback(() => {
        setOpenShareUrlHint(false)
    }, [setOpenShareUrlHint])

    const onCancelShare = useCallback(async () => {
        setOpenShareDialog(false)
    }, [setOpenShareDialog])


    const idea = ideaHook.data
    if (!idea) {
        return
    }

    let isActive = idea.startsAt && new Date(idea.startsAt) < new Date() && (!idea.endsAt || new Date(idea.endsAt) > new Date())

    let startsAt = null
    if (idea.startsAt) {
        startsAt = new Date(idea.startsAt)
    }
    let endsAt = null
    if (idea.endsAt) {
        endsAt = new Date(idea.endsAt)
    }

    let startEndAtSameDay = startsAt && endsAt && startsAt.getDate() === endsAt.getDate()

    let footerText = ""
    let hasStartsAt = false
    if (!isActive && startsAt) {
        hasStartsAt = true

        let timeSuffix = undefined
        if (startEndAtSameDay) {
            timeSuffix = null
        }

        let startsToday = startsAt.getDate() === new Date().getDate()
        if (startsToday) {
            footerText += formatTime({date: startsAt, timeSuffix: timeSuffix})
        } else {
            footerText += formatDateTime({date: startsAt})
        }
    }
    if (endsAt) {
        if (hasStartsAt) {
            footerText += " - "
        } else {
            footerText += " bis "
        }
        if (startEndAtSameDay) {
            footerText += formatTime({date: new Date(endsAt)})
        } else {
            footerText += formatDateTime({date: new Date(endsAt)})
        }
    }

    let moreMenuItems = []

    if (idea.authorId === props.session.user.id) {
        moreMenuItems.push(
            <MenuItem key={"idea-share"} onClick={onShareIdeaClick}>
                <ListItemIcon>
                    <Share/>
                </ListItemIcon>
                <Typography variant="inherit">Teilen</Typography>
            </MenuItem>
        )
        moreMenuItems.push(
            <MenuItem key={"idea-delete"} onClick={onDeleteIdeaClick}>
                <ListItemIcon>
                    <Delete/>
                </ListItemIcon>
                <Typography variant="inherit">Löschen</Typography>
            </MenuItem>
        )
    }

    let moreMenuOpen = Boolean(moreMenuAnchorEl)

    let action
    if (moreMenuItems.length > 0) {
        action =
            (
                <IconButton
                    aria-label="mehr"
                    id="more-button"
                    aria-controls={moreMenuOpen ? 'more-menu' : undefined}
                    aria-expanded={moreMenuOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={onMoreMenuClick}
                >
                    <MoreVert/>
                </IconButton>
            )
    }

    return (
        <>
            <Menu
                id="more-menu"
                open={moreMenuOpen}
                anchorEl={moreMenuAnchorEl}
                onClose={handleMoreMenuClose}
            >
                {moreMenuItems}
            </Menu>

            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    action
                }
                sx={{alignItems: 'center'}}
            >
                <ListItemAvatar>
                    <AvatarWidget id={idea.authorId} size={64}/>
                </ListItemAvatar>
                <ListItemText
                    primary={idea.title}
                    secondary={
                        <>
                            {/*<Typography*/}
                            {/*    component="span"*/}
                            {/*    variant="body2"*/}
                            {/*    sx={{color: 'text.primary', display: 'inline'}}*/}
                            {/*>*/}
                            {/*    {authorHook.data?.name}*/}
                            {/*</Typography>*/}
                            {footerText}
                        </>
                    }
                    sx={{marginLeft: '8px'}}
                />
            </ListItem>

            <Dialog
                open={openShareDialog}
                onClose={onCancelDeletion}
                aria-labelledby="share-dialog-title"
                aria-describedby="share-dialog-description"
            >
                <DialogTitle id="share-dialog-title">
                    {`Idee teilen`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="share-dialog-description">

                        Hier ist der Link zu Deiner Einladung
                        <Stack
                            direction="row"
                            padding={2}
                        >
                            <Box>
                                <Snackbar
                                    open={openShareUrlHint}
                                    autoHideDuration={4000}
                                    onClose={handleShareUrlHintClose}
                                    message={`Der Link wurde in die Zwischenablage kopiert.`}
                                />
                                <IconButton
                                    onClick={onShareUrlAddToClipboardClick}
                                >
                                    <ContentCopy
                                        cursor="pointer"
                                        sx={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                </IconButton>
                            </Box>
                            {shareUrl}
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelShare} autoFocus>Schließen</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmDeletionDialog}
                onClose={onCancelDeletion}
                aria-labelledby="delete-dialog-title"
            >
                <DialogTitle id="delete-dialog-title">
                    {`Idee wirklich löschen?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={onCancelDeletion} autoFocus>Abbrechen</Button>
                    <Button onClick={onDelete}>
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
